body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
}

.background_card {
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid gray;
}

.right_column {
  text-align: right;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.fill_right {
  text-align: right;
  flex: 1;
}

.table {
  width: auto;
  display: block;
}

.table_row {
  display: flex;
  align-items: stretch;
}
